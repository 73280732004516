import React from "react";
import { ChildMarkdownRemark, FriendFrontmatter } from "../models";

const FriendCard: React.FC<FriendCardProps> = ({ project }) => {
  return (
    <article className="relative flex flex-col p-6 bg-gray-100 rounded-md transition-colors duration-150 ease-in-out hover:bg-gray-50 dark:bg-gray-800 dark:hover:bg-gray-900">
      <h2 className="mb-2 text-gray-900 text-xl dark:text-gray-200">
        {project.frontmatter.name}
      </h2>
      <div className="flex space-x-2">
        <a
          className="transition-colors duration-150 ease-in-out hover:text-red-400 hover:shadow-underline dark:text-gray-50"
          href={project.frontmatter.url}
          target="_blank"
        >
          Website
        </a>
      </div>
    </article>
  );
};

FriendCard.displayName = "FriendCard";

export default FriendCard;

export type FriendCardProps = {
  project: ChildMarkdownRemark<FriendFrontmatter>;
};
