import React from "react";
import FriendCard from "./FriendCard";
import { ChildMarkdownRemark, Edge, FriendFrontmatter } from "../models";

const FriendGrid: React.FC<FriendCollectionProps> = ({ projects }) => {
  return (
    <div className="grid grid-cols-1 grid-rows-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
      {projects.map((x) => (
        <FriendCard key={x.node.id} project={x.node} />
      ))}
    </div>
  );
};

FriendCard.displayName = "FriendCard";

export default FriendGrid;

export type FriendCollectionProps = {
  projects: Edge<ChildMarkdownRemark<FriendFrontmatter>>[];
};
