import React from "react";
import { PageProps, useStaticQuery, graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { ChildMarkdownRemark, FriendFrontmatter } from "../models";
import MarkdownContent from "../components/MarkdownContent";
import PageHeader from "../components/PageHeader";
import FriendGrid from "../components/FriendGrid";

const FriendsPage: React.FC<PageProps> = () => {
  type FriendsPageQueryResult = {
    allMarkdownRemark: {
      edges: { node: ChildMarkdownRemark<FriendFrontmatter> }[];
    };
    file: { childMarkdownRemark: ChildMarkdownRemark<undefined> };
  };
  const {
    allMarkdownRemark: { edges: friends },
    file: {
      childMarkdownRemark: { html: prologue },
    },
  } = useStaticQuery<FriendsPageQueryResult>(graphql`
    query FriendsPageQuery {
      allMarkdownRemark(
        filter: {
          frontmatter: { draft: { ne: true } }
          fileAbsolutePath: { regex: "/friends/.*\\\\.md$/" }
        }
        sort: { order: DESC, fields: [frontmatter___date] }
      ) {
        edges {
          node {
            id
            frontmatter {
              name
              url
            }
          }
        }
      }
      file(sourceInstanceName: { eq: "pages" }, name: { eq: "friends" }) {
        childMarkdownRemark {
          html
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Friends" />
      <PageHeader title="Friends" />
      <MarkdownContent html={prologue} />
      <FriendGrid projects={friends} />
    </Layout>
  );
};

export default FriendsPage;
